import Parallax from 'parallax-js'
import "./import-jquery";
import "jquery-ui-dist/jquery-ui.js";

var ilust= document.querySelectorAll('.ilust');
var lista= [];
for (var i= 0; i<ilust.length;i++) {
    lista.push (new Parallax(ilust[i]));
}

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});

    $("#gracias").on("click",function() {
        $("#overay").addClass("hidden");
        $("#confirm").toggleClass("invisible");
    });

  $( "#formulario" ).submit(function( event ) {  
   
    $("#overay").removeClass("hidden");
    $("#confirm").toggleClass("invisible");

  
   var contenido = 'Nombre: '+$("#name").val()+' \\n Email: '+ $("#email").val()+ ' \\n Telef: ' + $("#phone").val() + '  \\n Comentarios: ' + $("#comments").val();
   console.log (contenido);
    var settings = {
        "url": "https://hmmrpmx09j.execute-api.us-east-1.amazonaws.com/default/sendMail",
        "method": "POST",
        "timeout": 0,
        "headers": {
           "x-api-key": "5WezZilJjC87YQh8fcIeP8DPPA4oASrIae8VPrMs",
          "Content-Type": "text/plain"
        },
        "data": '{"correoDestino":["sergio@simbiosys.es","marcos@simbiosys.es"],"correoFuente":"info@fidery.es","asunto" : "Nuevo Contacto web Fidery","contenido" :"'+contenido+'" }',
      };
      
      $.ajax(settings).done(function (response) {
        alert ("enviado");
      });    
      
      event.preventDefault();
  });

